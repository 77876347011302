const TRACE_API = {
  async GetApplicationNames() {
    return await $.getJSON(process.env.VUE_APP_TRACE_QUERY_API_URL + '/ApplicationNames');
  },

  async GetApplicationExceptionTypes(pFilter) {
    return await $.ajax({
      url: `${process.env.VUE_APP_TRACE_QUERY_API_URL}/ApplicationExceptionTypes`,
      data: JSON.stringify(pFilter)
    });
  },

  async GetExceptions(pExceptionInformation) {
    return await $.ajax(
      {
        url: process.env.VUE_APP_TRACE_QUERY_API_URL + '/Exceptions',
        data: JSON.stringify(pExceptionInformation)
      });
  },

  async GetOperationExecutions(pOperationExecution) {
    return await $.ajax(
      {
        url: process.env.VUE_APP_TRACE_QUERY_API_URL + '/OperationExecutionsCount',
        data: JSON.stringify(pOperationExecution)
      });
  },

  async GetKPIs() {
    return await $.getJSON(process.env.VUE_APP_TRACE_QUERY_API_URL + '/KPIs');
  }
}

export default TRACE_API;